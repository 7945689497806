<template>
  <v-sheet class="item" id="item" style="height: calc(100vh - 140px)">
    <v-row>
      <!-- {{ item.password.length }} -->
      <v-col md="12" class="py-0"></v-col>
      <v-col md="6" class="my--" style="background-color: #f0f8ff">
        <div class="d-flex align-center position-relative">
          <h1 class="custom-header-blue-text m-0 text-truncate me-3">#0{{ item.id }}</h1>
          <!-- <h2 color="blue--text darken-4 text-h2" class="me-2 ms-5 mb-0 text-truncate">Payment#</h2> -->
          <!-- <v-avatar color="blue darken-4" size="40"
            ><span class="white--text text-h5">5</span></v-avatar
          > -->
          <h2 color="blue--text darken-4 text-h2" class="me-1 ms-5 mb-0 text-truncate">PV# :</h2>
          <!-- <v-menu offset-y center transition="slide-y-transition" :close-on-content-click="false"> -->
            <div class="position-relative">
          <template>
            <!-- {{ item.bill.supplier }} -->
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <!-- Badge come when pv number will edit -->
                <v-btn
                  plain
                  @click="managePvDialog"
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text text-h4 font-weight-bold cursor px-0"
                >
                  <template v-if="item?.barcode">
                    
                    {{ item.code }} {{ item.barcode }}
                    <v-icon 
                      v-on:click="pvEdit = true"
                      class="ms-3"
                      link
                      size="18"
                      color="blue"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <template v-else> PV </template>
                </v-btn>
              </template>
              <span>Payment Number</span>
            </v-tooltip>
          </template>
          <v-card
            v-if="pvEdit"
            style="position: absolute; z-index: 9; top: 35px; left: 125px;"
            class="py-4 px-4"
            min-width="200"
            max-width="200"
          >
            <v-form
              ref="productAddForm"
              v-model.trim="formValid"
              lazy-validation
              v-on:submit.stop.prevent="updateOrCreate"
            >
              <div class="pb-0">
                <TextPayment
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  :prefixText="item?.code"
                  id="supplier"
                  placeholder=""
                  v-model="pv_number"
                >
                </TextPayment>
              </div>
              <div class="text-right mt-4">
                <v-btn :disabled="pageLoading" depressed tile class="" @click="pvEdit = false">
                  Close
                </v-btn>
                <v-btn
                  v-on:click="savePv"
                  color="blue darken-4"
                  depressed
                  tile
                  class="white--text ms-2"
                >
                  Save
                </v-btn>
                <!-- v-on:click="
                    () => {
                      pvConfirmDialog = true;
                      pvEdit = false;
                    } -->
                  
              </div>
            </v-form>
          </v-card>
        </div>
          <!-- </v-menu> -->
        </div>
      </v-col>
      <v-col md="6" class="text-right" style="background-color: #f0f8ff">
        <div class="d-flex align-center justify-content-end">
          <h1 class="custom-header-blue-text m-0 text-truncate me-3">Total:</h1>
          <h1 class="blue--text font-weight-bold mb-0">{{ formatMoney(item.payment) }}</h1>
          <v-btn
            :disabled="pageLoading"
            depressed
            tile
            class="mx-2 white--text"
            color="red darken-4"
            v-on:click="downloadPdf()"
          >
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
          <!-- <v-btn
            :disabled="pageLoading"
            depressed
            tile
            class="mx-2 white--text"
            color="red darken-4"
          >
            <v-icon>mdi-download</v-icon>
            Record
          </v-btn> -->
          <!-- <v-btn
            :disabled="pageLoading"
            depressed
            tile
            class="mx-2 white--text"
            color="blue darken-4"
            :to="{
              name: 'payment-made-update',
              params: { id: itemId },
              query: { t: new Date().getTime() },
            }"
          >
            Edit
          </v-btn> -->
          <!-- <v-btn
            :disabled="pageLoading"
            depressed
            tile
            class="mx-2 white--text"
            color="red lighten-1"
            v-on:click="deleteConfirm()"
          >
            Delete
          </v-btn> -->
          <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
            Back
          </v-btn>
        </div>
      </v-col>
      <v-col md="12" class="py-0" style="background-color: #f0f8ff">
        <v-row>
          <v-col md="3" class="pb-2 pt-0">
            <!-- <div class="pb-1"><b>Biller :</b> <span class="">Biller Name</span></div> -->
            <div class="pb-1">
              <b>Payment To :</b>
              <span
                v-if="!item?.bill?.receiving_add?.value"
                class="text-h6 font-weight-bold text-capitalize"
                >{{ item?.bill?.supplier?.company_name }}</span
              >
              <span v-else class="text-h6 font-weight-bold text-capitalize">{{
                item?.bill?.receiving_add?.value
              }}</span>
            </div>
            <!-- <div><b>Delivery Date :</b> 08/09/2023 11:17 am</div> -->
          </v-col>
          <v-col md="3" class="pb-2 pt-0">
            <div class="pb-1">
              <b>Payment Date :</b>
              <span class="text-h6 font-weight-bold"> {{ formatedateTime(item.payment_date) }}</span>
            </div>
            <!-- <div class="pb-1"><b>Amount Paid :</b> {{ formatMoney(item.payment_made) }}</div> -->
            <!-- <div class="pb-1"><b>Payment Mode : </b> {{ item.payment_mode }}</div> -->
            <!-- <div><b>Paid Through : </b> {{ item?.bill?.receiving_add?.payment_type }}</div> -->
          </v-col>
          <v-col md="3" class="pb-2 pt-0">
            <div class="pb-2">
              <b
                >Ref Number
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="disabled" size="18"
                      >mdi-help-circle-outline</v-icon
                    >
                  </template>
                  <span>Reference Number Printed on pdf</span>
                </v-tooltip>
                :</b
              >
             <span class="text-capitalize">{{ item.reference }}</span> 
            </div>
            <!-- <div><b>Receiving Warehouse : </b><span>Sutra</span></div> -->
          </v-col>
          <v-col md="3" class="pb-2 pt-0">
            <div class="pb-2"><b>Created by :</b> {{ item?.bill?.added_by.display_name }}</div>
            <div><b>Created Date :</b> {{ formatedateTime(item?.added_at) }}</div>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pt-0 pe-0" style="width: calc(100% - 320px); border-right: 1px solid #d8dbdd">
        <v-tabs
          fixed-tabs
          v-model="purchaseOrderTab"
          background-color="transparent"
          color="blue darken-4"
          class="customtabs"
        >
          <v-tab
            style="width: 100%"
            v-for="(tab, index) in purchase_order_tabs"
            :key="`product_${tab.key}_${index}`"
            :href="`#${tab.key}`"
            icons-and-text
          >
            <v-icon :size="18" class="me-1">{{ tab.icon }}</v-icon>
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-divider class="mt-0"></v-divider>
        <!-- <v-card>
            <v-card-item> -->
        <v-tabs-items v-model="purchaseOrderTab" style="height: 65vh; overflow-y: auto">
          <template v-for="(tab, index) in purchase_order_tabs">
            <v-tab-item :value="tab.key" :key="tab.key + '_' + index">
              <component :filteredBill="filteredBill" :is="tab.template" :items="item"
            /></v-tab-item>
          </template>
        </v-tabs-items>
        <!-- </v-card-item>
          </v-card> -->
      </v-col>
      <v-col style="max-width: 320px" class="ps-0 pt-0">
        <v-card elevation="1" height="100%">
          <v-list dense class="py-0 text-end">
            <div class="px-3 pt-0 pb-3 d-flex align-center">
              <div class="my-0">
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="supplier"
                  placeholder="Search..."
                  v-model="searchSupplier"
                  prepend-inner-icon="mdi-magnify"
                >
                </TextInput>
              </div>
              <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        color="blue darken-4"
                        depressed
                        tile
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        class="white--text ms-2 mt-3"
                        height="34"
                      >
                        <v-icon>{{ showFilter ? "mdi-filter-remove" : "mdi-filter-plus" }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Filter</span>
                  </v-tooltip>
                </template>
                <v-list nav dense>
                  <v-list-item class="flex-column">
                    <v-form
                      ref="productAddForm"
                      v-model.trim="formValid"
                      lazy-validation
                      v-on:submit.stop.prevent="updateOrCreate"
                    >
                      <div class="pb-2">
                        <v-select
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          :items.sync="paymentTypeList"
                          id="payment-type"
                          outlined
                          v-model="paymentType"
                          placeholder="Payment type"
                        >
                        </v-select>
                      </div>
                      <div class="pb-2">
                        <DatePicker
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="delivery-date"
                          v-model="delivery_date"
                          placeholder="Delivery Date"
                        ></DatePicker>
                      </div>
                      <div class="text-right">
                        <!-- <v-btn :disabled="pageLoading" depressed tile class="" :close-on-content-click="false">
                            Close
                          </v-btn> -->
                        <v-btn color="blue darken-4" depressed tile class="white--text ms-2">
                          Filter
                        </v-btn>
                      </div>
                    </v-form>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-divider class="my-0"></v-divider>

            <div class="puchase-listing">
              <v-list-item
                class="puchase-listing-item cursor-pointer"
                v-for="(item, i) in item?.bill?.purchase_order"
                @click="setCurrent(item)"
                :class="{ active: item.id === currentElement }"
                :key="i"
                style="border-bottom: 1px solid darkgrey"
              >
                <v-checkbox class="mx-0" v-model="checkboxs" disabled="true"></v-checkbox>
                <v-list-item-content>
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <p class="mb-2 font-weight-bold blue--text text-start">
                        {{ item.payment_mode }}
                      </p>
                      <div class="d-flex black--text">
                        <p class="mb-0 border-right me-2">{{ item.barcode }}</p>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"
                              ><v-icon size="15">mdi-calendar</v-icon>{{ formatedateTime(item?.delivery_date) }}</span
                            >
                          </template>
                          <span>Delivery Date</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div>
                      <p class="mb-0 font-weight-bold black--text"> {{ formatMoney(item.total) }}</p>
                      <v-chip
                        class="white--text"
                        small
                        :color="item.paid_status == 'Paid' ? 'success' : 'green'"
                        >{{ item.status }}</v-chip
                      >
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <DeleteTemplate
      type="item"
      :delete-text="deleteText"
      delete-note="All transactions of this item will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="goBack()"
    ></DeleteTemplate>
    <PVReEditDialog
      :dialog="paymentVoucherDialog"
      @close="paymentVoucherDialog = false"
      :barcode=item.code
      v-on:saveContactPerson="updateContactPerson"
      :pv=item.barcode
      @save="paymentVoucherSave()"
    ></PVReEditDialog>
    <Dialog :dialog="pvConfirmDialog" card-text-class="px-4 py-0">
      <template v-slot:title> Confirmation</template>
      <template v-slot:body>
        <v-container>
          <h4 class="mt-4">
            Are you sure you want edit to <b>{{ item.code}}{{ pv_number }}</b>
          </h4>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          :disabled="excelLoading"
          :loading="excelLoading"
          v-on:click="savePvNumber(pv_number)"
        >
          Save
        </v-btn>
        <v-btn class="ml-2" :disabled="excelLoading" depressed tile v-on:click="cancelPvcEdit">
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import moment from "moment-timezone";

import { toSafeInteger, find } from "lodash";
import ImageTemplate from "@/view/components/Image";
import ValueTemplate from "@/view/components/ValueTemplate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FIND_PV } from "@/core/lib/bill.lib";
// import { POST } from "@/core/services/store/request.module";
import { UPDATE_BARCODE } from "@/core/lib/bill.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import TextInput from "@/view/components/TextInput";
import Dialog from "@/view/components/Dialog";
import FileUpload from "@/view/components/FileUpload";
import DatePicker from "@/view/components/DatePicker";
import TextPayment from "@/view/components/TextPayment";
import TextAreaInput from "@/view/components/TextAreaInput";
import SelectInput from "@/view/components/SelectInput";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import PurchaseOrderBillsDetails from "./PurchaseOrderBillsDetails";
import POLineItemsTab from "./POLineItemsTab";
import AttactmentTab from "./AttactmentTab";
import LogTab from "./LogTab";
import PVReEditDialog from "./PVReEditDialog";
import PosMixin from "@/core/mixins/pos.mixin";

export default {
  mixins: [PosMixin],

  name: "item-detail",
  title: "Detail Item",
  data() {
    return {
      checkboxs: true,
      searchIteam: [],

      currentElement: 0,
      excelLoading: null,
      pageLoading: true,
      deleteText: null,
      formValid: false,
      deleteEndpoint: null,
      deleteDialog: false,
      paymentVoucherDialog: false,
      images: [],
      itemId: null,
      selectedItem: 0,
      checkbox: false,
      showFilter: false,
      delivery_date: "",
      searchSupplier: "",
      paymentType: null,
      pv_number: null,
      isPVEdit: false,
      pvEdit: false,
      pvConfirmDialog: false,
      paymentVoucherData: [
        {
          password: "",
          remark: "",
        },
      ],
      paymentTypeList: [
        {
          text: "Paid",
          value: "paid",
          color: "",
        },
        {
          text: "Unpaid",
          value: "Unpaid",
          color: "",
        },
      ],
      supplierFilter: [
        {
          title: "Actions",
          status: false,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "#",
          status: true,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "Details",
          status: false,
          decoration: {
            is: true,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "blue darken-4",
          },
        },
        {
          title: "Supplier",
          status: false,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
        {
          title: "Contact Person",
          status: false,
          decoration: {
            is: false,
            type: "chip",
            bgColor: "green",
            textColor: "#fff",
            customClass: "",
          },
        },
      ],
      POBillsData: [
        {
          id: 1,
          paymentReceivedNo: "#PR001",
          bill_number: "Bill# 101",
          payment_date: "10/09/2023",
          due_date: "18/10/2023",
          amount: "4,750.00",
          checkbox: true,
          payment_mode: "Cash",
        },
        {
          id: 2,
          paymentReceivedNo: "#PR002",
          bill_number: "Bill# 102",
          payment_date: "18/09/2023",
          due_date: "18/10/2023",
          amount: "3,750.00",
          checkbox: false,
          payment_mode: "Cheque",
        },
        {
          id: 3,
          paymentReceivedNo: "#PR003",
          bill_number: "Bill# 103",
          payment_date: "28/09/2023",
          due_date: "18/10/2023",
          amount: "4,750.00",
          checkbox: false,
          payment_mode: "Cash",
        },
      ],
      purchaseOrderTab: null,
      purchase_order_tabs: [
        {
          key: "line-items",
          title: "LIne Items",
          icon: "mdi-view-list",
          template: POLineItemsTab,
        },
        {
          key: "details",
          title: "Details",
          icon: "mdi-information-outline",
          template: PurchaseOrderBillsDetails,
        },
        {
          key: "attactments",
          title: "Attachments",
          icon: "mdi-file-multiple",
          template: AttactmentTab,
        },
        {
          key: "logs",
          title: "Logs",
          icon: "mdi-history",
          template: LogTab,
        },
      ],
      item: {
        bill_number: null,
        name_en: null,
        name_ch: null,
        reward: null,
        reward_point: null,
        sale: null,
        sale_amount: null,
        sale_start: null,
        sale_end: null,
        description: null,
        cuisine: null,
        category: null,
        sub_category: null,
        add_on_category: null,
        image: [],
        amount: null,
      },
      purchase_order_details: [
        {
          name: "Code",
          value: "BT002",
        },
        {
          name: "Category",
          value: "Disposable",
        },
        {
          name: "Department Category",
          value: "Bar Alcoholic",
        },
        {
          name: "Primary (sales) UOM",
          value: "BTL (BTL)",
        },
        {
          name: "Secondary (purchase) UOM",
          value: "CTN (CTN)",
        },
        {
          name: "Unit Description (primary sales uom)",
          value: "250ml",
        },
      ],
      purchaseOrder: [
        {
          id: 1,
          bill_number: "PO0001",
          supplier: "bthrust pvt ltd",
          refNumber: 101,
          delivery_date: "08/09/2023",
          project: "",
          amountValue: "4,750.00",
          amountStatus: "Paid",
          po_status: "Draft",
          paymentModes: "Cash",
          payment_voucher: "PV12",
        },
      ],
      filteredBill: {
        id: 1,
        paymentReceivedNo: "#PR001",
        bill_number: "Bill# 101",
        payment_date: "08/09/2023",
        amount: "4,750.00",
        checkbox: true,
        payment_mode: "Cash",
      },
      rowuct: {
        name: "Pineapple juice",
      },
    };
  },
  methods: {
    downloadPdf(){
      this.pdf= this.$route.params.id;
     console.log(this.pdf,'pdf')
     let downloadURL = process.env.VUE_APP_API_URL + `payment-pdf/${this.pdf}`;
     console.log(downloadURL,'downloadURL')
     downloadURL = new URL(downloadURL);
     window.open(downloadURL, "_blank");
    },
    updateContactPerson(param) {
      console.log(param)
      this.getItem();
    },
  savePv(){
    if (
          !this.pv_number
        ) {
          this.$store.commit(SET_ERROR, [
            { model: true, message: "The Pv number field is required" },
          ]);
          return false;
        }
  else{
    this.pvConfirmDialog = true;
                      this.pvEdit = false;
  }

  },
    formatedateTime(param) {
      return moment(param).format("DD/MM/YYYY ");
    },
    setCurrent(selectedIt) {
      // this.currentElement = item.id;
      const filteredData = this.POBillsData.filter((item) => item.id === selectedIt.id);

      // Convert the filtered array into an object
      const filteredObj = {};
      for (const item of filteredData) {
        filteredObj[item] = item;
      }

      this.filteredBill = filteredObj;
    },
    // filterToObject(selectedIt) {
    //   // Filter the sourceArray based on the selectedItem
    //   const filteredData = this.sourceArray.filter(item => item.id === selectedIt.id);

    //   // Convert the filtered array into an object
    //   const filteredObj = {};
    //   for (const item of filteredData) {
    //     filteredObj[item] = item;
    //   }

    //   this.filteredObject = filteredObj;
    // },
    paymentVoucherSave() {
      this.paymentVoucherDialog = false;
      this.pvEdit = true;
    },
    cancelPvcEdit() {
      this.pvConfirmDialog = false;
      this.pv_number = null;
    },
    // savePvNumber(pv_number) {
    //   try {
    //   const id=this.$route.params.id
    //   this.$store
    //     .dispatch(POST, {
    //       url: `update-barcode`,
    //       data: {  barcode: pv_number,
    //       record_id:id },

    //     })
    //     .then((data) => {
    //       this.document = data.id;
    //       console.log()
    //       // this.document = { ...this.document, id: 'k'+this.documents.length + 1 };
    //       if(data.id){
    //         this.item.barcode = data.barcode
    //         this.isPVEdit=true

    //       console.log(data)
    //       }

    //     })
    //   }
    //  catch (error) {
    //   alert(1)
    //     this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
    //   } finally {
    //     this.pageLoading = false;
    //   }

    //   // const item =  SEND_PV(pv_number,id);

    //   // console.log(item.Promise.PromiseResult,'item')
    //   this.pvConfirmDialog = false;
    //   this.isPVEdit = true;
    // },
    async savePvNumber(pv_number) {
      const _this = this;

      // const formErrors = _this.validateForm(_this.$refs.productAddForm);

      try {
        _this.pageLoading = true;
        const id = this.$route.params.id;
        if (_this.$route.params.id) {
          const product = await UPDATE_BARCODE(pv_number, id);
          console.log(product);
          console.log(product.barcode);
          this.item.barcode = product.barcode;
          this.pvConfirmDialog = false;
          this.isPVEdit = true;
          //         this.isPVEdit = true;
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "PV Number Updated Successfully." },
          ]);
        } else {
          _this.product.supplier = _this.suppliers;
          const customer = await UPDATE_BARCODE(_this.product);
          console.log(customer.id);
          _this.$router.replace({
            name: "product",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "PV Number Created Successfully." },
          ]);
          this.getItem();
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        this.pvConfirmDialog = false;
        this.isPVEdit = false;
      } finally {
        _this.pageLoading = false;
      }
    },
    //    savePvNumber(pv_number) {
    //   const id = this.$route.params.id;
    //   this.$store
    //     .dispatch(POST, {
    //       url: `update-barcode`,
    //       data: { barcode: pv_number, record_id: id },
    //     })
    //     .then((data) => {
    //       this.document = data.id;

    //       // this.document = { ...this.document, id: 'k'+this.documents.length + 1 };
    //       if (data.id) {
    //         this.item.barcode = data.barcode;
    //         this.isPVEdit = true;

    //       }
    //     })
    //     .catch((error) => {
    //       alert(2222)

    //       this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
    //     })
    //     .finally(() => {
    //       this.pageLoading = false;
    //     });

    //   // const item =  SEND_PV(pv_number,id);

    //   // console.log(item.Promise.PromiseResult,'item')
    //   this.pvConfirmDialog = false;
    //   this.isPVEdit = true;
    // },
    managePvDialog() {
      console.log(this.item.barcode);
      if (!this.item.barcode) {
        if (this.isPVEdit) {
          this.paymentVoucherDialog = true;
        } else {
          this.pvEdit = true;
        }
      }
    },
    // paymentVoucherDialog(){
    //    this.paymentVoucherDialog = false;
    //    this.managePvDialog = true;
    // },
    deleteConfirm() {
      this.deleteText = this.item.bill_number;
      this.deleteEndpoint = `item/${this.itemId}`;
      this.deleteDialog = true;
    },
    pageTitle() {
      if (this.item.name_en) {
        return this.item.name_en;
      }
      return "Purchase Order";
    },
    primaryImage() {
      const primary = find(this.images, { primary: 1 });
      if (!primary) {
        if (!this.images.length) {
          return null;
        }
        this.images[0].primary = 1;
        return this.images[0].url;
      }
      return primary.url;
    },
    async getItem() {
      try {
        const item = await FIND_PV(this.itemId);
        this.item = item[0];

        // this.item = {
        //   bill_number: item.bill_number,
        //   //name_en: item.name_en,
        //   name_ch: item.name_ch,
        //   reward: item.reward,
        //   reward_point: item.reward_point,
        //   sale: item.sale,
        //   sale_amount: item.sale_amount,
        //   sale_start: item.sale_start,
        //   sale_end: item.sale_end,
        //   description: item.description,
        //   cuisine: item.cuisine,
        //   category: item.category,
        //   sub_category: item.sub_category,
        //   add_on_category: item.add_on_category,
        //   image: [],
        //   amount: item.amount,
        // };

        // this.images = item.images;

        this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Item", disabled: true },
          { text: "Detail", disabled: true },
          { text: item.bill_number, disabled: true },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  // computed: {
  //   purchaseOrderTab: {
  //     set(val) {
  //       let query = { ...C };
  //       query.tab = val;
  //       query.t = new Date().getTime();
  //       if (val != this.purchaseOrderTab) {
  //         this.$router.replace({ query });
  //       }
  //     },
  //     get() {
  //       return this.$route.query.tab || "line-items";
  //     },
  //   },
  // },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Item", disabled: true },
      { text: "Detail", disabled: true },
    ]);

    const { id } = this.$route.params;
    if (id) {
      this.itemId = toSafeInteger(id);
      this.getItem();
    } else {
      this.goBack();
    }
  },

  components: {
    DeleteTemplate,
    ImageTemplate,
    ValueTemplate,
    PurchaseOrderBillsDetails,
    POLineItemsTab,
    AttactmentTab,
    LogTab,
    DatePicker,
    TextPayment,
    TextAreaInput,
    SelectInput,
    Dialog,
    FileUpload,
    PVReEditDialog,
    TextInput
  },
  computed: {},
};
</script>
<style scoped>
.customtabs .v-tabs .v-tab:not(.v-tab--active) {
  background-color: #fff !important;
}
.customtabs.v-tabs .v-slide-group__wrapper .v-tab {
  font-size: 13px !important;
  font-weight: 500 !important;
}
/* .po-line-items tbody tr:nth-child(even) {
    background-color: #e3eff9 !important;
  } */
.puchase-listing .puchase-listing-item:nth-child(odd) {
  background-color: #f2f6fa !important;
}
.puchase-listing .puchase-listing-item.active.cursor-pointer.v-list-item.theme--light {
  background-color: #9fcbf3 !important;
  /* opacity: 0.3!important; */
}
</style>
