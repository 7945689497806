<template>
  <!-- class="mt-2 pt-0" -->
  <v-text-field
    :id="id"
    :ref="customRef"
    :label="label"
    v-mask="'###'"
    :loading="loading"
    :disabled="disabled"
    :readonly="readonly"
    :clearable="clearable"
    :hide-details="hideDetails"
    :min="fieldType === 'number' && minMax.min"
    :max="fieldType === 'number' && minMax.max"
    outlined
    :rules="rules"
    class="pt-0"
    v-model.trim="textinput"
    :append-outer-icon="appendOuterIcon"
    :prepend-inner-icon="prependInnerIcon"
    :prepend-icon="prependIcon"
    :class="[{ 'mt-3 mt-2': !hideTopMargin }]"
    :placeholder="placeholder"
    :counter="counter"
    :counter-value="(v) => (v ? v.length : 0)"
    :hint="`Max character limit ${counter}`"
    :suffix="suffixText"
    :prefix="prefixText"
    persistent-hint
    :tabindex="tabIndex"
    :type="fieldType"
    :hide-spin-buttons="fieldType === 'number' && hideSpinBtns"
    v-on:click:clear="clearText()"
    v-on:click:append-outer="$emit('click:append-outer', true)"
    v-on:click:prepend="$emit('click:prepend', true)"
    v-on:change="$emit('change', $event)"
    v-on:keyup="$emit('keyup', $event)"
    v-on:keypress="$emit('keypress', $event)"
    v-on:keydown.esc.exact="$emit('keydown:esc', true)"
    v-on:keydown.enter.exact="$emit('keydown:enter', true)"
    v-on:keydown.tab.prevent="$emit('keydown:tab', $event)"
  >
    <template v-slot:append>
      <slot name="appendItem"></slot>
    </template>

    <template v-slot:prepend>
      <slot name="prependItem"></slot>
    </template>
  </v-text-field>
</template>
<script>
export default {
  name: "text-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    hideSpinBtns: {
      type: Boolean,
      default: false,
    },
    customRef: {
      type: String,
      default: "textInput",
    },
    fieldType: {
      type: String,
      default: "text",
    },
    minMax: {
      type: Object,
      default: () => {
        return { min: 0, max: 1000 };
      },
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    suffixText: {
      type: String,
      default: null,
    },
    prefixText: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: null,
    },
    counter: {
      type: [String, Number],
      default: 250,
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    prependInnerIcon: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideTopMargin: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textinput: null,
    };
  },
  watch: {
    value() {
      this.textinput = this.value;
    },
    textinput() {
      this.$emit("input", this.textinput);
    },
  },
  methods: {
    clearText() {
      this.$emit("input", null);
      this.$emit("click:clear", true);
    },
  },
  mounted() {
    this.textinput = this.value;
  },
};
</script>
